<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div class="d-flex justify-content-between">
          <h1 class="mr-sm-4 header-tablepage">Transaction Code DETAIL</h1>
          <b-dropdown
            v-if="isEdit"
            size="md"
            variant="filter"
            right
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <font-awesome-icon icon="download" class="pointer" /> Export
            </template>
            <b-dropdown-item href="#" @click="exportFile(1)"
              >Code Report</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="openModal()"
              >QR Code</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <b-tabs>
          <b-tab title="Set up">
            <div class="title-tabs">Set up Transaction Code</div>
            <b-container class="no-gutters bg-white py-2">
              <div class="py-3">
                <b-row>
                  <b-col lg="6" md="6" sm="12" xs="12">
                    <InputText
                      textFloat="Name"
                      placeholder="Name"
                      type="text"
                      name="name"
                      isRequired
                      :isValidate="$v.form.name.$error"
                      :v="$v.form.name"
                      v-model="form.name"
                      :disabled="isEdit"
                    />
                  </b-col>
                  <b-col lg="6" md="6" sm="12" xs="12">
                    <InputText
                      textFloat="Quantity"
                      placeholder="Quantity"
                      type="number"
                      name="quantity"
                      isRequired
                      :isValidate="$v.form.quantity.$error"
                      :v="$v.form.quantity"
                      v-model="form.quantity"
                      :disabled="isEdit"
                    />
                  </b-col>
                  <b-col lg="6" md="6" sm="12" xs="12">
                    <InputText
                      textFloat="Prefix"
                      placeholder="Prefix"
                      type="text"
                      name="prefix"
                      isRequired
                      :isValidate="$v.form.prefix.$error"
                      :v="$v.form.prefix"
                      :disabled="isEdit"
                      v-model="form.prefix"
                    />
                  </b-col>
                  <b-col lg="6" md="6" sm="12" xs="12">
                    <InputText
                      textFloat="Number Of Digit"
                      placeholder="Number Of Digit"
                      type="number"
                      name="Number_Of_Digit"
                      isRequired
                      :isValidate="$v.form.number_of_digit.$error"
                      :v="$v.form.number_of_digit"
                      :disabled="isEdit"
                      v-model="form.number_of_digit"
                    />
                  </b-col>
                  <b-col lg="6" md="6" sm="12" xs="12" class="mb-3">
                    <div class="label-text">Point</div>
                    <b-form-checkbox
                      size="md"
                      v-model="form.no_point"
                      :value="1"
                      :unchecked-value="0"
                      :disabled="isEdit"
                      >No Point</b-form-checkbox
                    >
                  </b-col>

                  <div class="break-normal"></div>
                  <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
                    <label class="label-text">
                      Valid From <span class="text-danger">*</span></label
                    >

                    <div
                      :class="[
                        'input-container py-1 px-1',
                        { error: $v.form.valid_from.$error },
                      ]"
                    >
                      <datetime
                        type="datetime"
                        v-model="form.valid_from"
                        placeholder="Please Select Date"
                        format="dd/MM/yyyy (HH:mm)"
                        value-zone="Asia/Bangkok"
                        @input="handleMinDate"
                        :disabled="isEdit"
                        ref="valid_from"
                      >
                      </datetime>
                      <div
                        :class="
                          'icon-primary text-right ' + id == 0
                            ? ''
                            : 'cursor-default'
                        "
                        @click="$refs.valid_from.isOpen = true"
                      >
                        <font-awesome-icon
                          icon="calendar-alt"
                          :class="
                            id == 0
                              ? 'pointer color-primary'
                              : 'cursor-default color-primary'
                          "
                          color="#B41BB4"
                        />
                      </div>
                    </div>
                    <div v-if="$v.form.valid_from.$error">
                      <span
                        class="text-error"
                        v-if="$v.form.valid_from.required == false"
                        >Please select date time.</span
                      >
                    </div>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" xs="12" class="mb-3">
                    <label class="label-text">
                      Valid To <span class="text-danger">*</span></label
                    >
                    <div
                      :class="[
                        'input-container py-1 px-1',
                        { error: $v.form.valid_to.$error },
                      ]"
                    >
                      <datetime
                        type="datetime"
                        v-model="form.valid_to"
                        :disabled="isEdit"
                        placeholder="Please Select Date"
                        format="dd/MM/yyyy (HH:mm)"
                        value-zone="Asia/Bangkok"
                        :min-datetime="form.valid_from"
                        ref="valid_to"
                      >
                      </datetime>
                      <div
                        :class="
                          'icon-primary text-right ' + id == 0
                            ? ''
                            : 'cursor-default'
                        "
                        @click="$refs.valid_to.isOpen = true"
                      >
                        <font-awesome-icon
                          icon="calendar-alt"
                          :class="
                            id == 0
                              ? 'pointer color-primary'
                              : 'cursor-default color-primary'
                          "
                          color="#B41BB4"
                        />
                      </div>
                    </div>
                    <div v-if="$v.form.valid_to.$error">
                      <span
                        class="text-error"
                        v-if="$v.form.valid_to.required == false"
                        >Please select date time.</span
                      >
                    </div>
                  </b-col>

                  <b-col lg="6" md="6" sm="12" xs="12">
                    <InputSelect
                      title="Branch"
                      name="type"
                      isRequired
                      valueField="id"
                      textField="name"
                      v-model="form.branch_id"
                      :v="$v.form.branch_id"
                      :isValidate="$v.form.branch_id.$error"
                      :value="form.branch_id"
                      :disabled="isEdit"
                      :options="branchList"
                    >
                      <template v-slot:option-first>
                        <b-form-select-option value="" disabled
                          >-- Select Branch --
                        </b-form-select-option>
                      </template>
                    </InputSelect>
                  </b-col>
                </b-row>
                <hr />
              </div>
              <div class="panelborder">
                <div class="title-tabs">
                  Select Product <span class="text-danger">*</span>
                </div>
                <div class="px-3 py-2">
                  <TableProductSelect
                    ref="productPromotionsSelect"
                    useBy="promotions"
                    :filter="filter"
                    :fields="fields"
                    :items="items"
                    :rows="rows"
                    :freezeProductList="freezeProductList"
                    :form="form"
                    @syncProductSelect="syncProductSelect"
                    @pagination="pagination"
                    @handleChangeTake="handleChangeTake"
                    @handleSearch="handleSearch"
                    :isDisabled="isDisabled"
                    :v="$v.form.product"
                  />
                </div>
                <div class="text-error" v-if="$v.form.product.$error">
                  <span v-if="this.form.product.length == 0"
                    >Please select at least 1 product.</span
                  >
                </div>
              </div>
            </b-container>
            <div class="no-gutters bg-white mt-3 py-2 px-3">
              <b-form-checkbox
                switch
                v-model="form.status"
                class="radio-active"
                size="lg"
                :value="1"
                :unchecked-value="0"
              >
                <span class="ml-2 main-label">{{
                  form.status ? "Active" : "Inactive"
                }}</span>
              </b-form-checkbox>
            </div></b-tab
          >
          <b-tab title="Activity">
            <div class="p-3 bg-white">
              <InputSelect
                title="Event type"
                name="type"
                class="mt-2"
                valueField="value"
                textField="text"
                v-model="isRandomReward"
                :options="[
                  { value: 0, text: 'None Event' },
                  { value: 1, text: 'Random Reward' },
                ]"
              >
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled checked>
                    -- Select Event Type --
                  </b-form-select-option>
                </template>
              </InputSelect>

              <InputSelect
                title="Select Item"
                name="type"
                isRequired
                :options="optionsReward"
                valueField="id"
                textField="name"
                v-model="form.random_reward.random_reward_id"
                class="mt-2"
                :disabled="isRandomReward != 1"
              >
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled checked>
                    -- Select Items --
                  </b-form-select-option>
                </template>
              </InputSelect>
            </div>
          </b-tab>
        </b-tabs>
        <FooterAction @submit="saveForm()" routePath="/transaction-code" />
        <ModalInputEmail
          ref="ModalInputEmail"
          :form="formExport"
          @changeEmail="(val) => (formExport.email = val)"
          @submit="exportFile(2)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  minValue,
} from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import TableProductSelect from "./tableProduct";
import ModalSelectBranch from "@/components/promotions/ModalSelectBranch";
export default {
  name: "Detail",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    TableProductSelect,
    ModalSelectBranch,
    ModalInputEmail,
  },
  validations() {
    return {
      form: {
        name: { required },
        quantity: { required },
        prefix: { required },
        number_of_digit: { required },
        valid_from: { required },
        valid_to: { required },
        branch_id: { required },
        product: {
          required,
          $each: {
            quantity: { required, minValue: minValue(1) },
          },
        },
      },
    };
  },

  computed: {
    isEdit() {
      return this.$route.params.id == 0 ? false : true;
    },
    isDisabled() {
      return (
        this.$route.params.id != 0 &&
        this.$moment().diff(this.$moment(this.form.valid_from || ""), "days") >=
          0
      );
    },
  },
  watch: {},
  data() {
    return {
      isLoading: false,
      branchList: [],
      percentOrbath: false,
      freezeProductList: [],
      isRandomReward: 0,
      form: {
        name: "",
        quantity: "",
        prefix: "",
        number_of_digit: 0,
        no_point: 0,
        branch_id: "",
        valid_from: "",
        valid_to: "",
        status: 1,
        product: [],
        random_reward: {
          id: 0,
          transaction_code_id: this.$route.params.id,
          random_reward_id: "",
        },
      },
      filter: {
        search: "",
        page: 1,
        take: 5,
      },

      id: this.$route.params.id,
      fields: [
        {
          key: "imageUrl",
          label: "Photo",
        },
        {
          key: "name",
          label: "Product",
          tdClass: "text-left",
        },
        {
          key: "ah_no",
          label: "SKU",
        },
        {
          key: "Price",
          label: "Price",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "Total",
          label: "Total",
        },
      ],
      items: [],
      rows: 0,
      isBusy: false,
      productSelected: [],
      delete_branch_id: [],
      productForm: {
        pageNo: 1,
        // start: 0,
        length: 5,
        promotion_id: this.$route.params.id,
        add_product_id: [],
        delete_product_id: [],
      },
      formExport: {
        transactioncode_id: this.$route.params.id,
        email: "",
      },
      optionsReward: [],
    };
  },
  created() {
    this.getBranchList();

    if (this.isEdit) this.getData();
    this.getRandomReward();
    // this.$showLoading();
  },
  destroyed() {},
  methods: {
    async getData() {
      const result = await this.$Axios(
        `${process.env.VUE_APP_API}/TransactionCode/${this.id}`
      );
      result.data.detail.random_reward = result.data.detail.random_reward || {
        id: 0,
        transaction_code_id: this.$route.params.id,
        random_reward_id: "",
      };
      this.form = result.data.detail;

      this.freezeProductList = result.data.detail.product;
      this.productForm.add_product_id = [
        ...result.data.detail.product.map((el) => el.product_id),
      ];
      this.getProductList();
    },

    async syncProductSelect(productList) {
      this.productForm.add_product_id = productList.add_product_id;
      this.productForm.delete_product_id = productList.delete_product_id;
      this.getProductList();
    },
    async getProductList() {
      this.isBusy = true;
      let payload = { ...this.productForm };

      payload.pageNo = this.filter.page;
      payload.search = this.filter.search;
      payload.length = this.filter.take;
      payload.promotion_id = 0;

      const result = await this.$Axios.post(
        `${process.env.VUE_APP_API}/promotion/ProductBySapPromotion`,
        payload
      );

      for (const product of result.data.detail.product_list) {
        const index =
          this.$refs.productPromotionsSelect.productSelectList.findIndex(
            (el) => el.product_id == product.id
          );
        const freezeIndex = this.freezeProductList.find(
          (el) => el.product_id == product.id
        );
        if (index == -1) {
          this.$refs.productPromotionsSelect.productSelectList.push({
            product_id: product.id,
            price: product.price,
            quantity: freezeIndex ? freezeIndex.quantity : 0,
            total: freezeIndex ? freezeIndex.quantity * freezeIndex.price : 0,
          });
        } else {
          let indexValue =
            this.$refs.productPromotionsSelect.productSelectList[index];
          this.$refs.productPromotionsSelect.productSelectList[index] = {
            product_id: product.id,
            price: indexValue.price == 0 ? product.price : indexValue.price,
            quantity:
              indexValue.quantity == 0
                ? product.quantity || 0
                : indexValue.quantity,
            total: indexValue.quantity * indexValue.price,
          };
        }
      }

      this.items = result.data.detail.product_list;

      this.rows = result.data.detail.total_product;
      this.form.product = this.$refs.productPromotionsSelect.productSelectList;
      this.isBusy = false;
    },
    async getBranchList() {
      const result = await this.$Axios(
        `${process.env.VUE_APP_API}/transactioncode/branch`
      );
      for (const branch of result.data.detail) {
        branch.name = `[${branch.plant_id}] ${branch.name} `;
      }
      this.branchList = result.data.detail;
    },
    handleSearch() {
      this.getProductList();
    },
    handleMinDate() {
      let diff = this.$moment(this.form.valid_to).diff(
        this.$moment(this.form.valid_from),
        "min"
      );

      if (diff < 0) this.form.valid_to = "";
    },
    pagination(val) {
      this.filter.page = val;
      this.getProductList();
    },
    handleChangeTake() {
      this.getProductList();
    },

    selectBranch(val) {
      const addID = val.filter((el) => !this.form.branch_id.includes(el));
      const deleteID = this.form.branch_id.filter((i) => !val.includes(i));

      this.form.add_branch_id = addID;
      // this.form.branch_id = val;
      this.form.delete_branch_id = deleteID;
    },
    async exportFile(type) {
      this.$EventBus.$emit("showLoading");
      // this.isLoading = true;
      var url = "";
      var response;
      if (type == 1) {
        url = `${process.env.VUE_APP_API}/transactioncode/code_report/${this.id}`;
        response = await this.$Axios(url, {
          responseType: "blob",
        });
        if (response.status == 200) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data])); // change object.
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `${this.form.name}_${type == 1 ? "Code_report" : "QRcode_report"}.${
              type == 1 ? "xlsx" : "zip"
            }`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        } else {
          this.errorAlert("Export fail");
        }
      } else {
        url = `${process.env.VUE_APP_API}/transactioncode/QRcode_report`;
        response = await this.$Axios.post(url, this.formExport);
      }

      // this.isLoading = false;
      this.$EventBus.$emit("hideLoading");
    },
    openModal() {
      this.formExport.email = "";
      this.$refs.ModalInputEmail.show();
    },
    async saveForm() {
      this.form.product = this.$refs.productPromotionsSelect.productSelectList;

      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }
      const url = `${process.env.VUE_APP_API}/TransactionCode`;
      this.$EventBus.$emit("showLoading");
      const requestPath = this.isEdit
        ? this.$Axios.put(url, this.form)
        : this.$Axios.post(url, this.form);
      const result = await requestPath;
      if (result.data.result) {
        this.successAlert().then(() => this.$router.push("/transaction-code"));
      } else {
        this.errorAlert(result.data.message);
      }
      this.$EventBus.$emit("hideLoading");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getRandomReward() {
      const result = await this.axios("/RandomReward/filter_random_reward");
      console.log(result);
      this.optionsReward = result.data.detail;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep h2#swal2-title.swal2-title {
  font-size: 24px !important;
}
::v-deep .price-baht {
  &::before {
    content: "฿";
  }
}
::v-deep .font-bold {
  font-weight: bold;
}
.select-branch {
  display: flex;
  width: max-content;
  align-items: center;
}
.panel-select-branch {
  gap: 10px;
  align-items: center;
  display: flex;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
::v-deep .dropdown-item {
  justify-content: center;
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
</style>
